import React, { FunctionComponent, useState } from "react";
import { inputFieldProp, OptionData } from "../../form.interfaces";
import validate_field, { ValidationResponse } from "../validate_field";
import {
	FormHelperText,
	MenuItem,
	SelectChangeEvent,
	FormControl,
	InputLabel,
} from "@mui/material";
import { StyledSelectField } from "../FormStyles";

export const RenderSelectField = ({
	index,
	keyName,
	field,
	setState,
	theme,
}: inputFieldProp) => {
	const [fieldState, setFieldState] = useState("");
	const [error, setError] = useState<undefined | ValidationResponse>(
		undefined
	);
	const hasRequiredAsterisk =
		theme?.components?.MuiInputLabel?.styleOverrides?.asterisk?.display !==
		"none";

	const handleChange = (event: SelectChangeEvent) => {
		setFieldState(event.target.value);
		setState(keyName, event.target.value);
	};
	return (
		<FormControl error={error?.error} fullWidth size="small">
			<InputLabel id={field.data.label}>
				{field.data.label}{" "}
				{field.data.validation?.required && hasRequiredAsterisk && " *"}
			</InputLabel>
			<StyledSelectField
				labelId={field.data.label}
				id={keyName}
				value={fieldState}
				label={field.data.label}
				onChange={handleChange}
				multiple={field.data.variant == "multiselect"}
				required={!!field.data.validation?.required}
				onBlur={(e) => {
					setError(validate_field(field.data.validation, fieldState));
				}}
				onFocus={(e) => {
					setError(undefined);
				}}
				error={error?.error}
				size="small"
			>
				{Object.values(field.data.options!).map(
					(option: OptionData, index: number) => {
						return (
							<MenuItem
								key={index}
								value={option.key_name ?? option.label}
								disabled={option.disabled}
							>
								{option.label}
							</MenuItem>
						);
					}
				)}
			</StyledSelectField>
			{error?.error && (
				<FormHelperText>{error.error_text}</FormHelperText>
			)}
		</FormControl>
	);
};
