import { filterState, Job } from "../joblist.interfaces";
import { getJobKeyFromNestedFilter } from "./getJobKeyFromNestedFilter";

export const getFilteredJobs = (
	jobList: Job[],
	filterSearchState: string,
	filterCheckState: filterState
): Job[] => {
	const isBeingSearched = (job: Job): boolean => {
		if (
			job.data.search_string
				.toLowerCase()
				.includes(filterSearchState.toLowerCase())
		) {
			return true;
		}
		return false;
	};

	const isCheckedOnFilter = (job): boolean => {
		let isFilter = true;
		Object.keys(filterCheckState).forEach((filter) => {
			if (
				filterCheckState[filter].length !== 0 &&
				!filterCheckState[filter].includes(
					getJobKeyFromNestedFilter(job, filter)
				)
			) {
				isFilter = false;
			}
		});
		return isFilter;
	};

	if (jobList) {
		return jobList.filter((job) => {
			if (isBeingSearched(job) && isCheckedOnFilter(job)) {
				return true;
			}
			return false;
		});
	}
	return [];
};
