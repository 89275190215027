import React, { useState } from "react";
import { inputFieldProp } from "../../form.interfaces";
import validate_field, { ValidationResponse } from "../validate_field";
import { StyledDateField } from "../FormStyles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

export const RenderDateInputField = ({
	index,
	keyName,
	field,
	setState,
}: inputFieldProp) => {
	const [fieldState, setFieldState] = useState(null);
	const [error, setError] = useState<null | ValidationResponse>(null);
	const handleChange = (event) => {
		setFieldState(event);
		setState(
			keyName,
			event.toLocaleDateString("fr-CA", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
			})
		);
	};
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<StyledDateField
				key={index}
				label={field.data.label}
				name={keyName}
				required={!!field.data.validation?.required}
				value={fieldState}
				onChange={handleChange}
				onBlur={(e) => {
					setError(validate_field(field.data.validation, fieldState));
				}}
				onFocus={(e) => {
					setError(false);
				}}
				helperText={error?.error && error.error_text}
				fullWidth
				slotProps={{
					textField: {
						helperText: error?.error_text,
						error: error?.error,
					},
				}}
				format={"dd/MM/yyyy"}
				disableFuture
				size="small"
			/>
		</LocalizationProvider>
	);
};
