import React, { useState } from "react";
import { RenderField } from "./FormFields/RenderField";
import { FieldData, Form, FormField, FormStep } from "../form.interfaces";
import { handleSubmit } from "./FormSubmit";
import RenderTextBlock from "../../../components/Text";
import { localeRouteInterface } from "../../../utils";

type Props = Form & {
	formId?: string;
	name: string;
	variant: string;
	context: any;
	args: localeRouteInterface;
	theme?: any;
	mobileView?: boolean;
};

const FormRenderer = ({
	formId,
	name,
	steps,
	variant,
	settings,
	context,
	args,
	theme,
	mobileView,
}: Props) => {
	const [stepState, updateStepState] = useState(0);
	const [formState, updateFormState] = useState({});
	const [formStatus, setFormStatus] = useState("form"); // formStatus = "form"|"already_applied"|"thank_you"
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");

	const step: FormStep = steps ? steps[stepState] : undefined;

	if (step == undefined) {
		return null;
	}

	const getKeyName = (field: FieldData) => {
		return field.key_name ? field.key_name : field.label;
	};

	const handleInputChange = (
		keyName: string,
		value: string | boolean | Array<string>
	) => {
		updateFormState((prevState) => ({
			...prevState,
			[keyName]: value,
		}));
	};

	const handleButtonPress = (e) => {
		e.preventDefault();
		if (steps.length - 1 > stepState) {
			updateStepState(stepState + 1);
		} else {
			const backgroundData = {};
			settings.background_data?.forEach((item) => {
				backgroundData[item.key_name] = item.value;
			});
			handleSubmit(
				{ ...formState, ...backgroundData },
				settings.source || null,
				settings.job_id || null,
				setLoading,
				setFormStatus,
				setError,
				args,
				formId
			);
		}
	};

	const fieldsToBeRendered = () => {
		return step.content.filter((field: FormField) => {
			if (field.data.conditional) {
				// Check if there's a matching conditional field
				const matchingConditional = field.data.conditional.some(
					(condition) => {
						const conditionalField = step.content.find(
							(formField: FormField) =>
								formField.data.key_name === condition.key_name
						);

						return (
							conditionalField &&
							formState[condition.key_name] === condition.value
						);
					}
				);

				return matchingConditional;
			}
			return true;
		});
	};
	const renderInputField = () => {
		return fieldsToBeRendered().map((field: FormField, index: number) => {
			let initialValue = null;
			if (args?.query[field.data.key_name]) {
				initialValue = args?.query[field.data.key_name] || "";
			}
			return (
				<div
					key={index}
					className={`${
						field.type != "text" &&
						field.data.label.length > 45 &&
						"col-[1_/_span_2]"
					}`}
				>
					<RenderField
						mobileView={mobileView}
						theme={theme}
						key={index}
						index={index}
						keyName={getKeyName(field.data)}
						field={field}
						setState={handleInputChange}
						initialValue={initialValue}
						context={context}
						args={args}
					/>
				</div>
			);
		});
	};

	const renderAltScreen = (content, button) => {
		const textData = { content: content };
		return (
			<>
				<div className={"py-3"}>
					<RenderTextBlock data={textData}></RenderTextBlock>
				</div>
				{button && (
					<button
						onClick={() => {
							setFormStatus("form");
							setError(null);
						}}
						className={`w-full cursor-pointer rounded bg-red1 px-4 py-3 text-center text-white ${
							variant == "grid" && "mx-auto md:w-72"
						}`}
					>
						{button}
					</button>
				)}
			</>
		);
	};

	if (formStatus === "form") {
		return (
			<form
				id="job-application-submit"
				name={name}
				onSubmit={handleButtonPress}
				className={`mx-auto flex flex-col gap-4`}
			>
				<h3 className="text-center font-bold">
					{settings?.header_text}
				</h3>
				<div
					className={`${
						variant == "grid" && "md:grid md:grid-cols-2"
					} flex flex-col gap-4 text-left`}
				>
					{!!step && renderInputField()}
				</div>
				{error && <div className="text-red1">{error}</div>}
				<input
					id="job-application-submit"
					type="submit"
					value={loading ? "Loading..." : settings?.button_text}
					disabled={loading}
					className={`w-full rounded ${
						loading ? "bg-grey1 text-black" : "bg-red1 text-white "
					} px-4 py-2.5 text-white cursor-pointer font-semibold ${
						variant == "grid" && "mx-auto md:w-72"
					}`}
				/>
			</form>
		);
	} else if (formStatus === "already_applied") {
		return renderAltScreen(
			(settings && settings.already_applied) ||
				"You have already applied",
			(settings && settings.already_applied_button) || "Back"
		);
	} else if (formStatus === "thank_you") {
		return renderAltScreen(
			(settings && settings.thank_you) ||
				"<h3><strong>Thank you for applying!</strong></h3>",
			(settings && settings.thank_you_button) || ""
		);
	}
};

export default FormRenderer;
