import React, { FunctionComponent, useState, useEffect } from "react";
import FormTypeInputFieldMap from "./FormTypeInputFieldMap";
import { inputFieldProp } from "../../form.interfaces";
import RenderTextBlock from "../../../../components/Text";

export const RenderField = ({
	index,
	keyName,
	field,
	setState,
	context,
	initialValue,
	args,
	theme,
	mobileView,
}: inputFieldProp) => {
	const Fallback = () => {
		return (
			<div className="mx-auto my-5 w-full items-center justify-center text-center">
				<p>Field not found</p>
			</div>
		);
	};

	if (field.type === "text") {
		return <RenderTextBlock data={field.data} />;
	} else {
		const Field = FormTypeInputFieldMap[field.type]
			? FormTypeInputFieldMap[field.type]
			: Fallback;
		return (
			<Field
				mobileView={mobileView}
				theme={theme}
				key={keyName}
				index={index}
				keyName={keyName}
				field={field}
				setState={setState}
				initialValue={initialValue}
				context={context}
				args={args}
			/>
		);
	}
};
