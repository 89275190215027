import React from "react";

const IngredientsAndSteps = ({ ingredients, steps }) => {
	const [view, setView] = React.useState("ingredients");

	const Ingredients = () => {
		return (
			<>
				<div className={"grid grid-cols-2 gap-4"}>
					{ingredients.map((ingredient, index) => {
						return (
							<div key={index}>
								<Ingredient ingredient={ingredient} />
							</div>
						);
					})}
				</div>
			</>
		);
	};

	const Ingredient = ({ ingredient }) => {
		return (
			<div className={"flex flex-row"}>
				<img height="64" width="64" src={ingredient.image} />
				<div>
					<div className={"font-semibold text-lg"}>
						{ingredient.name}
					</div>
					<div className={"text-grey3"}>{ingredient.amount}</div>
				</div>
			</div>
		);
	};

	const Steps = () => {
		return (
			<>
				<div className={"flex flex-col gap-8"}>
					{steps.map((step, index) => {
						return (
							<div key={index} className={""}>
								<Step step={step} number={index + 1} />
							</div>
						);
					})}
				</div>
			</>
		);
	};

	const Step = ({ step, number }) => {
		return (
			<div className={"flex flex-row gap-4"}>
				<div className={"font-bold text-green1 text-3xl"}>{number}</div>
				<div>{step.description}</div>
			</div>
		);
	};

	const IngredientIcon = () => {
		return (
			<svg
				className={`mx-auto stroke-grey3 ${
					view === "ingredients" && "!stroke-green1"
				}`}
				width="33"
				height="32"
				viewBox="0 0 33 32"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M5.83301 29.3333C9.83301 22 14.4997 16 19.1663 12"
					stroke-width="2"
					stroke-linecap="round"
				/>
				<path
					d="M27.167 4C27.167 14.9484 25.167 23.8439 17.167 25.2125C13.2214 25.8874 7.16699 23.7837 7.16699 17.6855C7.16699 9.47418 14.5003 4.68427 27.167 4Z"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		);
	};

	const StepsIcon = () => {
		return (
			<svg
				className={`mx-auto stroke-grey3 ${
					view === "steps" && "!stroke-green1"
				}`}
				width="33"
				height="32"
				viewBox="0 0 33 32"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M25.9511 2.30959C26.5209 1.78399 27.2719 1.49931 28.0469 1.51522C28.8219 1.53113 29.5607 1.84639 30.1083 2.39494C30.656 2.94348 30.9701 3.68272 30.9848 4.45773C30.9995 5.23274 30.7136 5.98335 30.1871 6.55226L23.8298 12.9163L19.5871 8.67359L25.9511 2.30959Z"
					stroke-width="1.86667"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M23.5554 12.6416C24.1701 17.2496 20.3394 24.8923 17.4661 27.7656C15.7783 29.4535 13.4891 30.4017 11.1021 30.4017C8.71514 30.4017 6.42595 29.4535 4.73811 27.7656C3.05027 26.0778 2.10205 23.7886 2.10205 21.4016C2.10205 19.0147 3.05027 16.7255 4.73811 15.0376C7.61144 12.1643 15.2541 8.33362 19.8621 8.94828"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M22.5283 11.6149C21.691 14.7763 16.803 21.3576 13.9297 24.2296C10.415 27.7443 6.30032 29.3283 4.73766 27.7656C3.17499 26.2029 4.75899 22.0883 8.27365 18.5736C11.1457 15.7003 17.727 10.8123 20.8883 9.97494"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		);
	};

	const IngredientsAndStepsButton = ({ button }) => {
		return (
			<div
				className={`w-1/2 p-1 cursor-pointer border-b-2 ${
					view === button
						? "text-green1 border-green1"
						: "border-grey1"
				}`}
				onClick={() => setView(button)}
			>
				{button === "ingredients" ? (
					<>
						<IngredientIcon />
						<div>Ingredients</div>
					</>
				) : (
					<>
						<StepsIcon />
						<div>Steps</div>
					</>
				)}
			</div>
		);
	};

	return (
		<>
			<div
				className={
					"flex flex-row text-center text-grey3 font-semibold py-8"
				}
			>
				<IngredientsAndStepsButton button={"ingredients"} />
				<IngredientsAndStepsButton button={"steps"} />
			</div>
			<div>{view === "ingredients" ? <Ingredients /> : <Steps />}</div>
		</>
	);
};

export default IngredientsAndSteps;
