"use client";
import React, { useState, useEffect } from "react";
import dynamic from "next/dynamic";
import { scrollProgress } from "./HDIWProgressSection.interface";
import { htmlToReactParser } from "shared/utils";
import RenderButtonsComponent from "shared/components/Button";
import RenderScrollProgressBar from "./partials/ScrollProgressBar";
import RenderSteps from "./partials/RenderSteps";
import RenderHDIWCards from "./partials/RenderHDIWCard";

// Dynamically import heavy components
const RenderAnimatedPlayerComponent = dynamic(
	() => import("@/components/AnimatedPlayer"),
	{ ssr: false }
);
const RenderMediaComponent = dynamic(() => import("@/components/Media"), {
	ssr: false,
});

type ScrollProgressProps = {
	data: scrollProgress;
	mobileView: boolean;
};

const RenderHDIWScrollProgress = ({
	data,
	mobileView,
}: ScrollProgressProps) => {
	const {
		epv_icon,
		checkmark_icon,
		header_block,
		step_blocks,
		buttons,
		card_block,
		cta_block,
		progress_bars,
	} = data;

	const [animationData, setAnimationData] = useState<any>(null);

	useEffect(() => {
		// Dynamically import the JSON animation file
		import("public/assets/consumer/lottie/tote_heavy.json").then(
			(animation) => {
				setAnimationData(animation.default);
			}
		);
	}, []);

	return (
		<div className=" bg-orange2 w-full font-inter leading-[19px] pt-16">
			<div className="md:max-w-[1440px] md:mx-auto">
				<div className="mx-6 md:px-24 py-3">
					<div className="flex flex-col md:flex-row md:max-w-[1000px] justify-between gap-8 z-20 relative md:mb-20">
						<div className="flex flex-col gap-8 justify-center">
							<span className="flex flex-col md:gap-4 gap-2 md:max-w-xs">
								{htmlToReactParser(
									mobileView && header_block.mobile_text
										? header_block.mobile_text
										: header_block.desktop_text
								)}
							</span>
							<span className="flex gap-4">
								<RenderButtonsComponent
									data={{ buttons: buttons }}
								/>
							</span>
						</div>
						<div className="md:w-[415px] md:h-[453px] w-[292px] h-[331px] md:mx-0 mx-auto">
							<RenderMediaComponent
								data={{
									media_src: header_block.image,
									styling: {
										media_sizing: {
											desktop: {
												height: "mx-auto",
												width: "mx-auto",
											},
										},
										rounded_size: "rounded",
									},
									style: {
										width: mobileView ? 292 : 415,
										height: mobileView ? 331 : 453,
										overflow: "visible",
									},
								}}
								mobileView={mobileView}
							/>
						</div>
					</div>
					<div
						className="flex justify-start relative"
						style={{ height: `100%` }}
					>
						<RenderScrollProgressBar
							mobileView={mobileView}
							epv_icon={epv_icon}
							progress_bars={progress_bars}
						/>
						<RenderSteps
							step_blocks={step_blocks}
							checkmark_icon={checkmark_icon}
							mobileView={mobileView}
						/>
					</div>
					<RenderHDIWCards
						card_block={card_block}
						mobileView={mobileView}
					/>
					<div className="flex gap-4 md:gap-28 md:py-24 py-14">
						<div className="w-40 h-28 md:w-[400px] md:h-72 flex justify-center">
							{animationData && (
								<RenderAnimatedPlayerComponent
									data={{ animation_data: animationData }}
								/>
							)}
						</div>
						<div className="flex flex-col md:gap-6 gap-2.5 items-start justify-center">
							{htmlToReactParser(
								mobileView
									? cta_block.mobile_text
									: cta_block.desktop_text
							)}
							<RenderButtonsComponent
								data={{ buttons: buttons }}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RenderHDIWScrollProgress;
