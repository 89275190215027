"use client";
import React from "react";
import StyledMedia from "./media.styles";
import { MediaProps } from "./media.interfaces";
import { RenderVideo } from "./partials/RenderVideo";
import { RenderImage } from "./partials/RenderImage";
import { Container } from "../../styles/components";
import { mediaParseLogic } from "../../utils/mediaParseLogic";

const RenderMediaComponent = ({ data, mobileView }: MediaProps) => {
	let { media_src, advanced, styling } = data;
	const currentMedia = mediaParseLogic(mobileView, media_src);
	return (
		<Container
			id={advanced?.anchor_name ? advanced.anchor_name : ""}
			className="media h-full w-full"
			$background_color={styling?.background_color}
		>
			<StyledMedia $styling={styling}>
				{currentMedia.type == "image" ? (
					<RenderImage data={data} mobileView={mobileView} />
				) : (
					<RenderVideo data={data} mobileView={mobileView} />
				)}
			</StyledMedia>
		</Container>
	);
};

export default RenderMediaComponent;
