import { Validation } from "../form.interfaces";

export type ValidationResponse = {
	error: boolean;
	error_type: string;
	error_text: string | undefined;
};

const validate_field = (
	validation: Validation | undefined,
	value: string | boolean | any
): ValidationResponse | null => {
	if (
		validation?.required &&
		(value == undefined || value == "" || value == false)
	) {
		return {
			error: true,
			error_type: "required",
			error_text: validation?.required!.validation_text,
		};
	}

	if (
		validation?.minlength &&
		(value as string).length < (validation?.minlength.value as number)
	) {
		return {
			error: true,
			error_type: "minlength",
			error_text: validation?.minlength.validation_text,
		};
	}

	if (validation?.minage) {
		const today = new Date();
		const birthDate = new Date(value);
		const ageDiff = today.getFullYear() - birthDate.getFullYear();
		const monthDiff = today.getMonth() - birthDate.getMonth();
		const minAge = parseInt(validation?.minage?.value as string);
		// Check if the user has already had their birthday this year
		const isOverMinAge =
			ageDiff > minAge ||
			(ageDiff === minAge && monthDiff > 0) ||
			monthDiff === 0;

		if (!isOverMinAge) {
			return {
				error: true,
				error_text: validation?.required!.validation_text,
				error_type: "minage",
			};
		}

		return null;
	}

	if (
		validation?.maxlength &&
		(value as string).length > (validation?.maxlength.value as number)
	) {
		return {
			error: true,
			error_type: "maxlength",
			error_text: validation?.maxlength.validation_text,
		};
	}

	if (validation?.regex) {
		const re = new RegExp(validation?.regex.value as string);
		if (value !== "" && !re.test(value as string)) {
			return {
				error: true,
				error_type: "regex",
				error_text: validation?.regex.validation_text,
			};
		}
	}

	return null;
};

export default validate_field;
