import React from "react";
import Card from "@mui/material/Card";
import { scrollProgress } from "../HDIWProgressSection.interface";
import { htmlToReactParser } from "@/utils";
import Image from "next/image";

const RenderHDIWCards = ({
	card_block,
	mobileView,
}: {
	card_block: scrollProgress["card_block"];
	mobileView: boolean;
}) => {
	const { desktop_header, mobile_header, cards } = card_block;

	const RenderCard = ({
		cards,
	}: {
		cards: scrollProgress["card_block"]["cards"];
	}) => {
		return (
			<div className="flex gap-4 flex-col md:flex-row items-center">
				{cards.map((card, index) => (
					<Card
						elevation={0}
						key={index}
						className="md:!w-[360px] !w-full !rounded-3lg"
						style={{
							boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.12)",
						}}
					>
						<div className="w-full h-full md:p-6 p-4 flex flex-col gap-4 justify-end items-center text-center">
							<Image
								src={card.image.desktop.src}
								alt={`${index}`}
								width={100}
								height={100}
								objectFit="contain"
								className=" aspect-square"
							/>
							<div className="flex flex-col gap-2 md:gap-4">
								{htmlToReactParser(
									mobileView && card.mobile_text
										? card.mobile_text
										: card.desktop_text
								)}
							</div>
						</div>
					</Card>
				))}
			</div>
		);
	};

	return (
		<div className="flex justify-center">
			<div className="flex flex-col gap-4 md:gap-8 pb-10 md:pt-64 max-w-full">
				{htmlToReactParser(mobileView ? mobile_header : desktop_header)}
				<RenderCard cards={cards} />
			</div>
		</div>
	);
};

export default RenderHDIWCards;
