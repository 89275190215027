import { apply_text, disabled_text } from "./text";
import { getSortedLocs } from "@/utils";

const RenderList = ({ locations, locale = "en" }) => {
	const sortedLocs = getSortedLocs(locations);
	return (
		<div id="stores-map-city-list" className="p-0 m-0 overflow-y-auto">
			{sortedLocs.map((location, index) => (
				<div
					key={index}
					className="flex p-4 mb-2 lg:mb-4 text-black rounded bg-grey1"
				>
					<div className="flex-1 bg-grey1">
						<h3 className="m-0 text-lg leading-6 text-black">
							{location.title}
						</h3>
						<p className="m-0 text-base leading-6 text-grey4">
							{location.address1}
						</p>
						<p className="m-0 text-base leading-6 text-grey4">
							{location.address2}
						</p>
						{location.dist ? (
							<p className="m-0 text-base leading-6 text-grey3">
								{location.dist.toFixed(1)} km
							</p>
						) : null}
					</div>
					<a
						className={`self-end text-white px-4 py-2 md:px-8 md:py-2.5 rounded-lg ${
							location.disabled
								? "bg-grey3 pointer-events-none"
								: "bg-red1"
						}`}
						href={location.disabled ? "" : location.url}
					>
						{location.disabled
							? disabled_text[locale]
							: apply_text[locale]}
					</a>
				</div>
			))}
		</div>
	);
};

export default RenderList;
