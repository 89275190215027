"use client";
import { motion, useTransform, useScroll } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { scrollProgress } from "../HDIWProgressSection.interface";
import { htmlToReactParser } from "@/utils";
import { mediaParseLogic } from "@/utils/mediaParseLogic";

const RenderSteps = ({
	step_blocks,
	checkmark_icon,
	mobileView,
}: {
	step_blocks: scrollProgress["step_blocks"];
	checkmark_icon: string;
	mobileView: boolean;
}) => {
	const RenderStepNumber = ({
		step_icon,
		animation_state,
		padding_off,
	}: {
		step_icon: string;
		animation_state: boolean;
		padding_off: boolean;
	}) => {
		return (
			<div
				className={`md:w-12 md:h-12 w-10 h-10 relative md:min-w-12 min-w-10 ${
					padding_off ? "md:mt-0" : "md:mt-12"
				}`}
			>
				<motion.img
					initial={{ scale: 0 }}
					animate={animation_state ? { scale: 1 } : { scale: 0 }}
					transition={{ duration: 0.5, ease: "easeInOut" }}
					className="absolute top-0 left-0 z-[5]"
					src={checkmark_icon}
					alt="Checkmark"
				/>
				<img
					className="relative left-0"
					src={step_icon}
					alt="Step Icon"
				/>
			</div>
		);
	};

	const RenderStepText = ({ text }: { text: string }) => {
		return (
			<span className="flex flex-col gap-2 md:max-w-[296px] max-w-[75%] md:relative ">
				{htmlToReactParser(text)}
			</span>
		);
	};

	const RenderStepImage = ({
		image,
		desktop_only,
		parralax,
	}: {
		image: string;
		desktop_only?: boolean | undefined;
		parralax?: { image: string; mask: string };
	}) => {
		const { scrollY } = useScroll();
		const scrollTransform = useTransform(
			scrollY,
			[0, mobileView ? 800 : 600],
			[0, -115]
		);
		if (parralax == undefined) {
			return (
				<img
					className={`md:h-[400px] h-[320px] w-full md:max-w-[25rem] z-20 ${
						desktop_only && "hidden md:block"
					}`}
					src={image}
				/>
			);
		} else {
			return (
				<div
					className={`md:h-[500px] h-[450px] md:min-w-[400px] md:w-[400px] w-[360px] mx-auto md:mx-0 z-30 relative ${
						desktop_only && "hidden md:block"
					}`}
				>
					<img
						className={`h-full w-full z-20 absolute top-0 left-0`}
						src={image}
						loading="lazy"
					/>
					<div
						className="absolute md:h-[322px] h-[290px] md:top-[105px] top-[95px] w-[55%] z-[25] left-[22%] pl-1 overflow-clip"
						style={{ borderRadius: "4.5px" }}
					>
						<motion.img
							className=" h-112 w-full"
							style={{ y: scrollTransform }}
							src={parralax.image}
						/>
					</div>
					<img
						className={`h-full w-full z-30 absolute top-0 left-0`}
						src={parralax.mask}
					/>
				</div>
			);
		}
	};

	const RenderStep = ({
		block,
		index,
	}: {
		block: scrollProgress["step_blocks"][0];
		index: number;
	}) => {
		const stepRef: React.MutableRefObject<null | HTMLDivElement> =
			useRef(null);
		const [isEPVOverlapping, setisEPVOverlapping] = useState(false);

		useEffect(() => {
			const handleScroll = () => {
				const targetDivRect = stepRef.current?.getBoundingClientRect();

				if (targetDivRect) {
					const windowHeight = window.innerHeight;

					const isOverlapping =
						(targetDivRect.top / windowHeight) * 100 <
							(mobileView
								? 45
								: 15 + (index > 1 ? index : index * 5) * 5) &&
						(targetDivRect.top / windowHeight) * 100 > -50;

					setisEPVOverlapping(isOverlapping);
				}
			};

			window.addEventListener("scroll", handleScroll);

			return () => {
				window.removeEventListener("scroll", handleScroll);
			};
		}, []);

		return (
			<div
				className={`w-full ${index == 0 && "relative md:-top-16"}`}
				ref={stepRef}
			>
				<div
					className={`md:flex md:max-w-[1000px] gap-10 ${
						block.orientation == "reversed"
							? "items-start"
							: "items-center"
					} w-full hidden`}
				>
					<RenderStepNumber
						step_icon={block.step_icon}
						animation_state={isEPVOverlapping}
						padding_off={block.orientation == "reversed"}
					/>
					<div
						className={`flex flex-col w-full md:max-w-[850px] gap-10 justify-between ${
							block.orientation == "reversed"
								? "md:flex-row-reverse items-start"
								: "md:flex-row items-center"
						}`}
					>
						<RenderStepImage
							image={
								mediaParseLogic(mobileView, block.image)?.src
							}
							desktop_only={block.desktop_only}
							parralax={block.parralax}
						/>
						<div
							className={
								block.orientation == "reversed"
									? "relative"
									: "h-fit relative"
							}
						>
							<RenderStepText
								text={
									mobileView
										? block.mobile_text
										: block.desktop_text
								}
							/>
						</div>
					</div>
				</div>
				<div className="flex flex-col gap-8 md:hidden items-start w-full">
					<div className="flex justify-between gap-4 w-full md:pl-0 pl-[3%]">
						<RenderStepNumber
							step_icon={block.step_icon}
							animation_state={isEPVOverlapping}
							padding_off={false}
						/>
						<RenderStepText
							text={
								mobileView
									? block.mobile_text
									: block.desktop_text
							}
						/>
					</div>
					<RenderStepImage
						image={mediaParseLogic(mobileView, block.image)?.src}
						desktop_only={block.desktop_only}
						parralax={block.parralax}
					/>
				</div>
			</div>
		);
	};

	return (
		<div className="flex flex-col md:justify-around items-start gap-20 absolute h-full w-full md:pr-5 pt-5 md:pt-60">
			{step_blocks.map((block, index) => (
				<RenderStep block={block} key={index} index={index} />
			))}
		</div>
	);
};

export default RenderSteps;
