import dynamic from "next/dynamic";
import RenderBlogContentComponent from "./components/BlogContent";

const ComponentMap = {
	blogs: {
		element: dynamic(() => import("./components/Blogs"), { ssr: false }),
		context: ["blogs"],
	},
	blogContent: {
		element: RenderBlogContentComponent,
		context: ["blogs"],
	},
};

export default ComponentMap;
