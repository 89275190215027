"use client";
import Image from "next/image";
import { buttonData } from "../buttons.interfaces";
import StyledButton from "../buttons.styles";
import { FlexContainer } from "../../../styles/components";
import RenderTextBlock from "../../Text";

const RenderButton = ({ button, index }) => {
	const { text, styling, icon, link, target, onClick } = button;

	const updateLink = () => {
		if (link && link !== "") {
			window.location.href = link;
		}
	};

	const delay = () => {
		onClick ? onClick() : updateLink();
	};

	return (
		<StyledButton
			key={index}
			$styling={styling}
			onClick={() => setTimeout(delay, 200)}
			formTarget={target}
		>
			{styling?.background_image ? (
				<></>
			) : (
				<>
					{icon && (
						<Image
							src={icon}
							width={styling?.icon_size ? styling?.icon_size : 24}
							height={
								styling?.icon_size ? styling?.icon_size : 24
							}
							alt={text}
						/>
					)}
					{text && (
						<FlexContainer
							$flex_col={true}
							className={`leading-4 text-${styling?.text_color}`}
						>
							<RenderTextBlock data={{ content: text! }} />
						</FlexContainer>
					)}
				</>
			)}
		</StyledButton>
	);
};

export default RenderButton;
