"use client";
import React, { useRef } from "react";
import IngredientsAndSteps from "./partials/IngredientsAndSteps";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useScrollResize, useContentScroll } from "./partials/scroll";

type BlogsProps = {
	data: any;
	mobileView: boolean;
	context?: any;
	pageArgs?: any;
};

const RenderRecipe = ({ context, pageArgs }: BlogsProps) => {
	const recipeList = context?.recipes?.data || [];
	const recipe = recipeList.find((obj) => {
		return obj.slug === pageArgs.route[1];
	});

	const RecipeImage = () => {
		const initialHeight = 588; // numbers need to be adjusted after implementing with the API
		const minHeight = 416;
		const imageHeight = useScrollResize(initialHeight, minHeight);
		return (
			<div
				className={`inset-0 w-full md:!h-full md:bg-center md:!bg-cover md:bg-local relative ${
					window.scrollY > 88 ? "bg-fixed bg-top" : "bg-center"
				}`}
				style={{
					backgroundImage: `url("https://i.pinimg.com/originals/a5/40/73/a54073be3858e9015e98af9ba8448421.png")`,
					height: `500px`,
					backgroundSize:
						window.scrollY > 88 ? `auto ${imageHeight}px` : "cover",
				}}
			>
				<div
					className={
						"absolute bottom-8 left-4 md:top-4 md:right-4 md:left-auto md:bottom-auto"
					}
				>
					<RecipeTime />
				</div>
			</div>
		);
	};

	const RecipeTime = () => {
		return (
			<div
				className={
					"bg-white rounded-full text-green1 px-2 py-1 flex gap-1"
				}
			>
				<AccessTimeIcon />
				<span>{recipe.time}</span>
			</div>
		);
	};
	const RecipeContent = () => {
		return (
			<div className={"p-6 md:p-10 text-pretty"}>
				<h2>
					<strong>{recipe.title}</strong>
				</h2>
				<h5>{recipe.description}</h5>
				<IngredientsAndSteps
					ingredients={recipe.ingredients}
					steps={recipe.steps}
				/>
			</div>
		);
	};

	const contentRef = useRef(null);
	const containerRef = useRef(null);

	useContentScroll(contentRef, containerRef);

	if (recipe) {
		return (
			<>
				<div
					ref={containerRef}
					className="flex flex-col md:flex-row md:mt-24 md:h-[700px] text-grey5"
				>
					<div className={"md:w-[55%] min-h-[300px]"}>
						<RecipeImage />
					</div>
					<div
						ref={contentRef}
						className={
							"flex-1 bg-white rounded-xl -mt-4 md:mt-0 z-50 overflow-scroll"
						}
					>
						<RecipeContent />
					</div>
				</div>
			</>
		);
	}
};

export default RenderRecipe;
