import React, { FunctionComponent, useState } from "react";
import { inputFieldProp } from "../../form.interfaces";
import validate_field, { ValidationResponse } from "../validate_field";
import { StyledTextField } from "../FormStyles";

export const RenderTextInputField = ({
	index,
	keyName,
	field,
	setState,
	initialValue,
}: inputFieldProp) => {
	const [fieldState, setFieldState] = useState(initialValue || "");
	const [error, setError] = useState<null | ValidationResponse>(null);
	const handleChange = (event) => {
		setFieldState(event.target.value);
		setState(keyName, event.target.value);
	};

	return (
		<StyledTextField
			key={index}
			label={field.data.label}
			type={field.type}
			name={keyName}
			multiline={
				field.data.variant && field.data.variant == "multiline"
					? true
					: false
			}
			variant="outlined"
			required={field.data.validation?.required ? true : false}
			inputProps={{
				maxLength: field.data.validation?.maxlength?.value,
				minLength: field.data.validation?.minlength?.value,
			}}
			value={fieldState}
			onChange={handleChange}
			onBlur={(e) => {
				setError(validate_field(field.data.validation, fieldState));
			}}
			onFocus={(e) => {
				setError(undefined);
			}}
			error={error?.error}
			helperText={error?.error && error.error_text}
			minRows={4}
			size="small"
			fullWidth
		/>
	);
};
